@tailwind base;
@tailwind components;
@tailwind utilities;

/* poppins*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Platypi:ital,wght@0,300..800;1,300..800&display=swap");
body {
  font-family: "Platypi", "Poppins", serif;
}

/*services section animation*/

.animate_services_item{
  transition: all ease-in-out;
  animation: moveSideways 1s linear forwards;
  opacity: 0;
 
}
@keyframes moveSideways{
  0%{
    opacity: 0;
    transform: translateX(-20px);
  }
  100%{
    opacity: 1;
    transform: translateX(0);
  }
}


.animate_services_item:nth-child(2){
  animation-delay: .5s;
}
.animate_services_item:nth-child(3){
  animation-delay: 1s;
}


/* education section*/
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #c3b9be;
  top: 7px;
  bottom: 17px;
  border-radius: 5px;
  left: 50%;
  padding: 5px;
  animation: moveline 8s linear forwards;
  z-index: 1;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.5); 
}



@keyframes moveline{
  0%{
    height: 0;
  }
  100%{
    height: 99%;
  }
}


.timeline_items{
  transition: all ease-in-out;
  animation: movedown 1s linear forwards;
  opacity: 0;
 
}

@keyframes movedown{
  0%{
    opacity: 1;
    transform: translateY(-30px);
  }
  100%{
    opacity: 1;
    transform: translateY(0);
  }
}

.timeline_items:nth-child(2){
  animation-delay: 1s;
}
.timeline_items:nth-child(3){
  animation-delay: 2s;
}
.timeline_items:nth-child(4){
  animation-delay: 3s;
}
.timeline_items:nth-child(5){
  animation-delay: 4s;
}
.timeline_items:nth-child(6){
  animation-delay: 5s;
}
.timeline_items:nth-child(7){
  animation-delay: 6s;
}
.timeline_items:nth-child(8){
  animation-delay: 7s;
}
.timeline_items:nth-child(9){
  animation-delay: 8s;
}
.timeline_items:nth-child(10){
  animation-delay: 9s;
}
.timeline_items:nth-child(11){
  animation-delay: 10s;
}


.right_arrow {
  height: 0;
  width: 0;
  top: 28px;
  position: absolute;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 15px solid rgb(191, 187, 187);
  z-index: 1;
  right: -17px;
 
}

.left_arrow {
  height: 0;
  width: 0;
  top: 28px;
  position: absolute;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 15px solid rgb(191, 187, 187);
  z-index: 1;
  left: -15px;
}


@media screen and (max-width: 600px) {
  
  .timeline::after{
    left: 4px;
    
  }
 .right_arrow{
    left: -15px;
    border-left: 0;
    border-right: 15px solid rgb(191, 187, 187);
 }

 
}